

.mobile-container-transactions{
    width: 90%;
    margin: 25px auto;
}
.home-mobile-top{
    display: flex;
    justify-content: space-between;   
}
.name-circle{
    width: 36px;
    height: 36px;
    background: #D9D9D9;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.name-circle p{
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.3px;
    color: #FFFFFF;
}
.business-name{
    display: flex;
    align-self: center;
    gap: 6px;
}
.business-name p{
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.3px;
    color: #757575;

}

.home-mobile-top {
  display: flex;
  justify-content: space-between;
}
.name-circle {
  width: 36px;
  height: 36px;
  background: #d9d9d9;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.name-circle p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #ffffff;
}
.business-name {
  display: flex;
  align-self: center;
  gap: 6px;
}
.business-name p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #757575;

}

.home-mobile-top {
  display: flex;
  justify-content: space-between;
}
.name-circle {
  width: 36px;
  height: 36px;
  background: #d9d9d9;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.name-circle p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #ffffff;
}
.business-name {
  display: flex;
  align-self: center;
  gap: 6px;
}
.business-name p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #757575;

}
/* .business-name img{
    width: 4.8px;
    height: 9.6px;
} */
.home-mobile-top-left {
  display: flex;
  gap: 6px;
}
.home-mobile-top-right {
  display: grid;
  place-items: center;
}
.home-mobile-top-right img {
  align-self: center;
}
.home-mobile-middle {
  margin-top: 36px;
  width: 100vw;
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: space-between;
  gap: 6px;
}
.home-mobile-middle-box {
  width: 90vw;
  height: 95px;
  background-color: white;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  padding: 12px;
  border: 1px solid #c4c4c4;
}
.home-mobile-middle-box h3 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.3px;
  color: #757575;
}
.home-mobile-middle-box h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #757575;
}
.home-mobile-middle-box p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.3px;
  color: #757575;
}
.home-mobile-middle-box:nth-child(3) {
  margin-right: 5vw;

}
.home-middle-close p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #121212;
  margin-top: 36px;
  margin-bottom: 24px;
}
.left-table-circle {
  width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-flex {
  display: flex;
  justify-content: space-between;
}
.left-table {
  display: flex;
  gap: 12px;
}
.left-table-details h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}
.left-table-details p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}
.left-table-details {
  align-self: center;
}
.right-table {
}
.right-table p {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}
.transactions-mobile-table {
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 72vh;
  overflow-y: auto;
}
.transactions-mobile-table-home {
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 50vh;
  overflow-y: auto;
}
.pop-body h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}
.create-out {
  display: flex;
  gap: 15px;
  height: 60px;
  width: 100%;

  background: white;
  position: fixed;
  bottom: 0vh;
  left: 0;
  z-index: 26000;
  padding-left: 24px;
}
.create-out p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #8f00ff;
}
.create-out img {
  width: 18px;
  height: 19px;
  align-self: center;
}

.left-table-details{
    align-self: center;
}
.right-table{
   
}
.right-table p{
   
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575;
}
.transactions-mobile-table{
    display: flex;
    flex-direction: column;
    gap: 28px;
    height: 72vh;
    overflow-y: scroll;
}
.transactions-mobile-table-home{
    display: flex;
    flex-direction: column;
    gap: 28px;
    height: 50vh;
    overflow-y: auto;
}
.pop-body h2{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575
}
.create-out{
    display: flex;
    gap: 15px;
    height: 60px;
    width: 100%;
    
    background: white ;
    position: fixed;
    bottom: 0vh;
    left: 0;
    z-index: 26000;
    padding-left: 24px;
}
.create-out p{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #8F00FF;
}
.create-out img{
    width: 18px;
    height: 19px;
    align-self: center;

}

.home-middle-close p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #121212;
  margin-top: 36px;
  margin-bottom: 24px;
}
.left-table-circle {
  width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-flex {
  display: flex;
  justify-content: space-between;
}
.left-table {
  display: flex;
  gap: 12px;
}
.left-table-details h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}
.left-table-details p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}
.left-table-details {
  align-self: center;
}
.right-table {
}
.right-table p {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}
.transactions-mobile-table {
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 72vh;
  overflow-y: auto;
}
.transactions-mobile-table-home {
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 50vh;
  overflow-y: auto;
}
.pop-body h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}
.create-out {
  display: flex;
  gap: 15px;
  height: 60px;
  width: 100%;

  background: white;
  position: fixed;
  bottom: 0vh;
  left: 0;
  z-index: 26000;
  padding-left: 24px;
}
.create-out p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #8f00ff;
}
.create-out img {
  width: 18px;
  height: 19px;
  align-self: center;
}
